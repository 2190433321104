import React, {useEffect} from 'react'
import {Route, Routes, useNavigate} from "react-router-dom"
import {Main, Login} from "./pages"
import {useSelector} from "react-redux"

const App = () => {
    const {authData} = useSelector(state => state.auth)
    const navigate = useNavigate()

    useEffect(() => {
        if (!authData) {
            navigate('/')
        }
    }, [])

    return (
        <div>
            {authData ? (
                <Routes>
                    <Route path='/*' element={<Main/>}/>
                </Routes>
            ) : (
                <Routes>
                    <Route path='/' element={<Login/>}/>
                </Routes>
            )}
        </div>
    );
};

export default App;