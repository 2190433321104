import {
    CREATE_LEGEND, DELETE_LEGEND, GET_LEGENDS,
    SET_LOADING
} from '../constants/actionTypes'
import * as legendsApi from '../api/legends'

export const createLegend = (formData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await legendsApi.createLegend(formData)

        dispatch({ type: CREATE_LEGEND, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getLegends = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await legendsApi.getLegends()

        dispatch({ type: GET_LEGENDS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}
export const deleteLegend = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await legendsApi.deleteLegend(id)

        dispatch({ type: DELETE_LEGEND, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}