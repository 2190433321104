export const SET_LOADING='SET_LOADING'

// Months
export const GET_MONTHS = 'GET_MONTHS'

// Blog
export const GET_BLOGS = 'GET_BLOGS'
export const CREATE_BLOG = 'CREATE_BLOG'
export const DELETE_BLOG = 'DELETE_BLOG'

// Members
export const GET_MEMBERS = 'GET_MEMBERS'
export const CREATE_MEMBER = 'CREATE_MEMBER'
export const DELETE_MEMBER = 'DELETE_MEMBER'
export const SELECT_TEAM = 'SELECT_TEAM'

// Gallery
export const GET_PHOTOS = 'GET_PHOTOS'
export const ADD_PHOTO = 'ADD_PHOTO'
export const DELETE_PHOTO = 'DELETE_PHOTO'
export const GET_VIDEOS = 'GET_VIDEOS'
export const ADD_VIDEO = 'ADD_VIDEO'
export const DELETE_VIDEO = 'DELETE_VIDEO'

// Calendar
export const GET_CURRENT_CALENDAR = 'GET_CURRENT_CALENDAR'
export const CREATE_NEW_PERFORMANCE = 'CREATE_NEW_PERFORMANCE'

// Auth
export const LOGIN = 'LOGIN'
export const REGISTER = 'REGISTER'
export const LOGOUT  = 'LOGOUT '

// Premieres
export const GET_PREMIERES = 'GET_PREMIERES'
export const CREATE_PREMIERE = 'CREATE_PREMIERE'
export const DELETE_PREMIERE = 'DELETE_PREMIERE'

// Legends
export const CREATE_LEGEND = 'CREATE_LEGEND'
export const GET_LEGENDS = 'GET_LEGENDS'
export const DELETE_LEGEND = 'DELETE_LEGEND'

// Hero Images
export const CREATE_HERO_SLIDE = 'CREATE_HERO_SLIDE'
export const GET_HERO_SLIDES = 'GET_HERO_SLIDES'
export const DELETE_HERO_SLIDE = 'DELETE_HERO_SLIDE'

// Upcoming Performances
export const CREATE_UPCOMING_PERFORMANCE = 'CREATE_UPCOMING_PERFORMANCE'
export const GET_UPCOMING_PERFORMANCES = 'GET_UPCOMING_PERFORMANCES'
export const DELETE_UPCOMING_PERFORMANCE = 'DELETE_UPCOMING_PERFORMANCE'
