import axios from 'axios'

const API = axios.create({baseURL: 'https://api.abelyantheatre.am'})

API.interceptors.request.use((req) => {
    if (localStorage.getItem('auth')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('auth')).token}`;
    }

    return req
})

export default API