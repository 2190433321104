import {
    LOGIN, LOGOUT, REGISTER,
    SET_LOADING
} from '../constants/actionTypes'
import * as authApi from '../api/auth'

export const login = (formData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await authApi.login(formData)

        dispatch({ type: LOGIN, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const register = (formData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await authApi.register(formData)

        dispatch({ type: REGISTER, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const logout = () => async (dispatch) => {
    try {
        dispatch({ type: LOGOUT })
    } catch (error) {
        console.log(error.message);
    }
}