export const convertImageFileToBase64 = async (file, setImage) => {
    const base64 = await convertBase64(file)
    setImage(base64)
}

const convertBase64 = (file) => {
    if(typeof file === 'string') return

    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        file && fileReader?.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}