import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getPremieres} from "../actions/premieres";
import {PremiereCard} from "../components";

const Premieres = () => {
    const {premieres} = useSelector(state => state.premieres)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPremieres())
    }, []);

    return (
        <div>
            <div className={'flex items-center justify-between'}>
                <h2 className={'font-medium text-2xl'}>Premieres</h2>
            </div>
            <div className={'p-4 grid gap-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 place-items-center'}>
                {premieres?.map(premiere => <PremiereCard key={premiere._id} {...premiere} />)}
            </div>
        </div>
    );
};

export default Premieres;