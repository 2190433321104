import React from 'react';

const LegendCard = ({image, name, _id, handleDelete}) => {
    return (
        <div className={'relative group w-fit max-w-[300px] border rounded-lg overflow-hidden'}>
            <button onClick={() => handleDelete(_id)} className={'absolute group-hover:block hidden top-4 right-4 bg-red-500 text-white py-1 px-2 rounded-md'}>Delete</button>

            <img src={image} alt="legend"/>
            <div className={'p-4'}>
                <p className={'text-xl'}>{name.am}</p>
            </div>
        </div>
    );
};

export default LegendCard;