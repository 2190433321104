import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {CreateLegendModal, LegendCard} from "../components";
import {deleteLegend, getLegends} from "../actions/legends";

const Legends = () => {
    const [create, setCreate] = useState(false)
    const {legends} = useSelector(state => state.legends)
    const dispatch = useDispatch()

    const toggleCreate = () => {
        setCreate(prev => !prev)
    }

    const handleDelete = (id) => {
        dispatch(deleteLegend(id))
    }

    useEffect(() => {
        dispatch(getLegends())
    }, [])

    return (
        <div>
            <div className={'flex items-center justify-between'}>
                <h2 className={'font-medium text-2xl'}>Legends</h2>
                <button onClick={toggleCreate} className={'border rounded-md text-xl py-2 px-4'}>Create</button>
            </div>
            <div className={'my-10 grid gap-x-6 gap-y-[70px] place-items-center xl:grid-cols-3 md:grid-cols-2 grid-cols-1'}>
                {create && <CreateLegendModal close={toggleCreate}/>}

                {legends?.map(legend => <LegendCard key={legend._id} handleDelete={handleDelete} {...legend} />)}
            </div>
        </div>
    )
}

export default Legends;