import React from 'react';
import {Sidebar} from "../components";
import {Route, Routes} from "react-router-dom";
import {
    Blog,
    Calendar,
    GalleryPhotos,
    GalleryVideos,
    HeroSlides,
    Legends,
    Members,
    Premieres,
    UpcomingPerformances
} from "./index"

const Main = () => {
    return (
        <div>
            <Sidebar/>

            <div className={'max-w-7xl px-4 mx-auto my-0'}>
                <Routes>
                    <Route path={'/*'} element={<Blog />} />
                    <Route path={'/members/*'} element={<Members />} />
                    <Route path={'/calendar/*'} element={<Calendar />} />
                    <Route path={'/gallery-photos/*'} element={<GalleryPhotos /> } />
                    <Route path={'/gallery-videos/*'} element={<GalleryVideos />} />
                    <Route path={'/premieres/*'} element={<Premieres />} />
                    <Route path={'/legends/*'} element={<Legends />} />
                    <Route path={'/hero-slides/*'} element={<HeroSlides />} />
                    <Route path={'/upcoming/*'} element={<UpcomingPerformances />} />
                </Routes>
            </div>
        </div>
    );
};

export default Main;