import React, {useEffect, useState} from 'react'
import {CloseIcon, UploadIcon} from "../../assets/img/icons"
import {useDispatch} from "react-redux"
import {convertImageFileToBase64} from "../../utils/convertImageFileToBase64"
import {createMember} from "../../actions/members"
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {positions} from "../../constants/positions";

const initialFormData = {
    name: {en: '', am: '', ru: ''},
    team: '',
    image: null,
    position: ''
}

const CreateMemberModal = ({close}) => {
    const [data, setData] = useState(initialFormData)
    const [imagePreview, setImagePreview] = useState('')
    const dispatch = useDispatch()

    const handleChange = (e) => {
        if (!e.target.files) {
            const parsedName = e.target.name.split('-')
            const parsedLang = parsedName[1]

            if(parsedLang) {
                setData({
                    ...data,
                    [parsedName[0]]: {
                        ...data[parsedName[0]],
                        [parsedName[1]]: e.target.value
                    }
                })
            } else {
                setData({...data, [e.target.name]: e.target.value})
            }
        } else {
            setData({...data, image: e.target.files[0]})
        }
    }

    const handleSubmit = async () => {
        const formData = new FormData()

        for (const key in data) {
            if(key === 'name') {
                formData.append(key, JSON.stringify(data[key]))
            } else {
                formData.append(key, data[key])
            }
        }

        dispatch(createMember(formData))

        close()
        setData(initialFormData)
    }

    useEffect(() => {
        data.image && convertImageFileToBase64(data.image, setImagePreview)
    }, [data.image])

    return (
        <div className={'fixed z-50 w-full h-full top-0 left-0'}>
            <div onClick={close} className={'absolute w-full h-full top-0 left-0 bg-[#C5B4BF] bg-opacity-50'}></div>
            <div
                className={'absolute border bg-white rounded-md shadow-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-3xl'}>
                <div className={'p-4 border-b flex items-center justify-between'}>
                    <h2 className={'font-medium text-2xl'}>Create new Member</h2>
                    <button onClick={close} className={'p-1 rounded-md transition hover:bg-gray-200'}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className={'p-4'}>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <TextField id="outlined-basic" name={'name-am'} value={data.name.am} onChange={handleChange} label="Full Name Armenian" variant="outlined" />
                    </div>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <TextField id="outlined-basic" name={'name-en'} value={data.name.en} onChange={handleChange} label="Full Name English" variant="outlined" />
                    </div>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <TextField id="outlined-basic" name={'name-ru'} value={data.name.ru} onChange={handleChange} label="Full Name Russian" variant="outlined" />
                    </div>
                    <div className={'mt-5'}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Team</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={data.team}
                                label="Team"
                                name={'team'}
                                onChange={handleChange}
                            >
                                <MenuItem selected value={'management'}>Management</MenuItem>
                                <MenuItem value={'creative'}>Creative</MenuItem>
                                <MenuItem value={'cast'}>Cast Members</MenuItem>
                                <MenuItem value={'service'}>Service</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Position</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={data.position}
                                label="Position"
                                name={'position'}
                                onChange={handleChange}
                            >
                                {positions.map(role =>  <MenuItem value={role.key} key={role.key}>{role.name}</MenuItem>)}
                            </Select>
                        </FormControl>                    </div>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <label className={'flex items-center justify-between'}>
                            <div className={'flex flex-col items-start'}>
                                <span>Photo</span>
                                <input type="file" onChange={handleChange} name={'image'} accept="image/jpeg, image/png"
                                       className={'hidden'} id={'image'}/>
                                <label
                                    className={'flex items-center gap-1 cursor-pointer shadow-md border rounded-md py-1 px-2 mt-1'}
                                    htmlFor="image">
                                    <UploadIcon/>
                                    <span>Upload</span>
                                </label>
                            </div>
                            <div>
                                {imagePreview && <img
                                    className={'max-w-[150px] shadow-md rounded-md select-none pointer-events-none'}
                                    src={imagePreview} alt="image preview"/>}
                            </div>
                        </label>
                    </div>
                </div>
                <div className='flex justify-end items-center gap-2 p-4 border-t'>
                    <button onClick={close}
                            className='px-2 py-1 transition rounded-md border shadow-md hover:bg-gray-200'>Cancel
                    </button>
                    <button onClick={handleSubmit}
                            className='px-2 py-1 transition rounded-md border shadow-md hover:bg-gray-200'>OK
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateMemberModal;