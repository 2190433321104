import {
    GET_PREMIERES,
    SET_LOADING
} from '../constants/actionTypes'
import * as premieresApi from '../api/premieres'

export const getPremieres = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await premieresApi.getPremieres()

        dispatch({ type: GET_PREMIERES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}