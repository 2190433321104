import { combineReducers } from 'redux'

import calendar from "./calendar"
import blog from "./blog"
import members from "./members"
import gallery from "./gallery"
import premieres from "./premieres"
import auth from "./auth"
import legends from "./legends"
import heroSlides from "./heroSlides"
import upcoming from "./upcoming"

export default combineReducers({
    calendar,
    blog,
    members,
    gallery,
    premieres,
    auth,
    legends,
    heroSlides,
    upcoming,
})