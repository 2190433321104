import {CREATE_HERO_SLIDE, DELETE_HERO_SLIDE, GET_HERO_SLIDES, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    heroSlides: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_HERO_SLIDE:
            return { ...state, heroSlides: [action.payload.heroSlide, ...state.heroSlides] }
        case GET_HERO_SLIDES:
            return { ...state, heroSlides: action.payload.heroSlides }
        case DELETE_HERO_SLIDE:
            return { ...state, heroSlides: state.heroSlides.filter(heroSlide => heroSlide._id !== action.payload.id) }
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
}