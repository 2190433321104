import {
    CREATE_NEW_PERFORMANCE,
    GET_MONTHS,
    SET_LOADING
} from '../constants/actionTypes'
import * as calendarApi from '../api/calendar'
import * as performanceApi from '../api/performances'

export const getCurrentCalendar = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await calendarApi.getCurrentCalendar()

        dispatch({ type: GET_MONTHS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const createNewPerformance = (formData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await performanceApi.createNewPerformance(formData)

        dispatch({ type: CREATE_NEW_PERFORMANCE, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}