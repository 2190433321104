import {
    CREATE_MEMBER, DELETE_MEMBER,
    GET_MEMBERS, SELECT_TEAM,
    SET_LOADING
} from '../constants/actionTypes'
import * as membersApi from '../api/members'

export const getMembers = (team) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await membersApi.getMembers(team)

        dispatch({ type: GET_MEMBERS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const createMember = (formData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await membersApi.createMember(formData)

        dispatch({ type: CREATE_MEMBER, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}
export const deleteMember = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await membersApi.deleteMember(id)

        dispatch({ type: DELETE_MEMBER, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const selectTeam = (team) => async (dispatch) => {
    try {
        dispatch({ type: SELECT_TEAM, payload: {team} })
    } catch (error) {
        console.log(error.message);
    }
}