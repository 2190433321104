import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux"
import {convertImageFileToBase64} from "../../utils/convertImageFileToBase64"
import {CloseIcon, UploadIcon} from "../../assets/img/icons"
import {createUpcomingPerformance} from "../../actions/upcoming"

const initialFormData = {
    image: null,
}

const CreateHeroSliderModal = ({close}) => {
    const [data, setData] = useState(initialFormData)
    const [imagePreview, setImagePreview] = useState('')
    const dispatch = useDispatch()

    const handleChange = (e) => {
        if (!e.target.files) {
            setData({...data, [e.target.name]: e.target.value})
        } else {
            setData({...data, image: e.target.files[0]})
        }
    }

    const handleSubmit = async () => {
        const formData = new FormData()

        for(const key in data) {
            formData.append(key, data[key])
        }

        dispatch(createUpcomingPerformance(formData))

        close()
        setData(initialFormData)
    }

    useEffect(() => {
        data.image && convertImageFileToBase64(data.image, setImagePreview)
    }, [data.image])

    return (
        <div className={'fixed w-full h-full top-0 left-0 z-50'}>
            <div onClick={close} className={'absolute w-full h-full top-0 left-0 bg-[#C5B4BF] bg-opacity-50'}></div>
            <div
                className={'absolute border bg-white rounded-md shadow-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-3xl'}>
                <div className={'p-4 border-b flex items-center justify-between'}>
                    <h2 className={'font-medium text-2xl'}>Create upcoming performance</h2>
                    <button onClick={close} className={'p-1 rounded-md transition hover:bg-gray-200'}>
                        <CloseIcon />
                    </button>
                </div>
                <div className={'p-4'}>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <label className={'flex items-center justify-between'}>
                            <div className={'flex flex-col items-start'}>
                                <span>Image</span>
                                <input type="file" onChange={handleChange} name={'image'} accept="image/jpeg, image/png, image/webp"
                                       className={'hidden'} id={'image'}/>
                                <label
                                    className={'flex items-center gap-1 cursor-pointer shadow-md border rounded-md py-1 px-2 mt-1'}
                                    htmlFor="image">
                                    <UploadIcon/>
                                    <span>Upload</span>
                                </label>
                            </div>
                            <div>
                                {imagePreview && <img className={'max-w-[150px] shadow-md rounded-md select-none pointer-events-none'} src={imagePreview} alt="image preview" />}
                            </div>
                        </label>
                    </div>
                </div>
                <div className='flex justify-end items-center gap-2 p-4 border-t'>
                    <button onClick={close} className='px-2 py-1 transition rounded-md border shadow-md hover:bg-gray-200'>Cancel</button>
                    <button onClick={handleSubmit} disabled={!data.image} className={`px-2 py-1 transition rounded-md border shadow-md ${data.image && 'bg-blue-600 text-white'}`}>OK</button>
                </div>
            </div>
        </div>
    );
};

export default CreateHeroSliderModal;