import React from 'react'
import { Stack, Pagination } from "@mui/material";

const Paginate = ({ count, page, handleChange }) => {
    if (count > 1) {
        return (
            <div className='flex justify-center' id='pagination'>
                <Stack spacing={2} className={'my-5'}>
                    <Pagination hideNextButton hidePrevButton count={count} variant="warning" page={page} size={'small'} onChange={handleChange} />
                </Stack>
            </div>
        )
    }
}

export default Paginate