import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux"
import {convertImageFileToBase64} from "../../utils/convertImageFileToBase64"
import {CloseIcon, UploadIcon} from "../../assets/img/icons"
import {addPhoto} from "../../actions/gallery"

const CreatePhotoModal = ({close}) => {
    const [images, setImages] = useState(null)
    const [imagePreview, setImagePreview] = useState('')
    const dispatch = useDispatch()

    const handleChange = (e) => {
        setImages([...e.target.files])
    }

    const handleSubmit = async (e) => {
        const formData = new FormData()

        images.forEach(image => {
            formData.append('images', image)
        })

        dispatch(addPhoto(formData))

        close()
        setImages(null)
    }

    return (
        <div className={'fixed w-full h-full top-0 left-0 z-50'}>
            <div onClick={close} className={'absolute w-full h-full top-0 left-0 bg-[#C5B4BF] bg-opacity-50'}></div>
            <div
                className={'absolute border bg-white rounded-md shadow-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-3xl'}>
                <div className={'p-4 border-b flex items-center justify-between'}>
                    <h2 className={'font-medium text-2xl'}>Add new Photo</h2>
                    <button onClick={close} className={'p-1 rounded-md transition hover:bg-gray-200'}>
                        <CloseIcon />
                    </button>
                </div>
                <div className={'p-4'}>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <label className={'flex items-center justify-between'}>
                            <div className={'flex flex-col items-start'}>
                                <span>Image</span>
                                <input type="file" multiple={true} onChange={handleChange} name={'image'} accept="image/jpeg, image/png, image/webp"
                                       className={'hidden'} id={'image'}/>
                                <label
                                    className={'flex items-center gap-1 cursor-pointer shadow-md border rounded-md py-1 px-2 mt-1'}
                                    htmlFor="image">
                                    <UploadIcon/>
                                    <span>Upload</span>
                                </label>
                            </div>
                            <div>
                                {images?.length > 0 && <p>{images?.length} {images?.length > 1 ? 'images' : 'image'} selected</p>}
                            </div>
                        </label>
                    </div>
                </div>
                <div className='flex justify-end items-center gap-2 p-4 border-t'>
                    <button onClick={close} className='px-2 py-1 transition rounded-md border shadow-md hover:bg-gray-200'>Cancel</button>
                    <button onClick={handleSubmit} disabled={!images?.length} className={`px-2 py-1 transition rounded-md border shadow-md ${images?.length && 'bg-blue-600 text-white'}`}>OK</button>
                </div>
            </div>
        </div>
    );
};

export default CreatePhotoModal;