import {
    CREATE_HERO_SLIDE,
    DELETE_HERO_SLIDE, GET_HERO_SLIDES,
    SET_LOADING
} from '../constants/actionTypes'
import * as heroApi from '../api/hero'

export const createHeroSlide = (formData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await heroApi.createHeroSlide(formData)

        dispatch({ type: CREATE_HERO_SLIDE, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getHeroSlides = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await heroApi.getHeroSlides()

        dispatch({ type: GET_HERO_SLIDES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}
export const deleteHeroSlide = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await heroApi.deleteHeroSlide(id)

        dispatch({ type: DELETE_HERO_SLIDE, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}