import {
    CREATE_BLOG, DELETE_BLOG, GET_BLOGS,
    SET_LOADING
} from '../constants/actionTypes'
import * as blogApi from '../api/blog'

export const createBlog = (formData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await blogApi.createBlog(formData)

        dispatch({ type: CREATE_BLOG, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getBlogs = (page) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await blogApi.getBlogs(page)

        dispatch({ type: GET_BLOGS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}
export const deleteBlog = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await blogApi.deleteBlog(id)

        dispatch({ type: DELETE_BLOG, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}