import React from 'react';

const ImageItem = ({handleDelete, _id, src}) => (
    <div className={'relative group'}>
        <button onClick={() => handleDelete(_id)}
                className={'absolute top-4 right-4 group-hover:block hidden rounded-md bg-red-500 py-1 px-2 text-white'}>Delete
        </button>
        <img src={src} loading={'lazy'} alt="image"/>
    </div>
)

export default ImageItem;