import {
    ADD_PHOTO,
    ADD_VIDEO, DELETE_PHOTO, DELETE_VIDEO,
    GET_PHOTOS, GET_VIDEOS,
    SET_LOADING
} from '../constants/actionTypes'
import * as galleryApi from '../api/gallery'

export const getPhotos = (page) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await galleryApi.getGalleryImages(page)

        dispatch({ type: GET_PHOTOS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const addPhoto = (files) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await galleryApi.createGalleryImage(files)

        window.location.reload()

        dispatch({ type: ADD_PHOTO, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const deletePhoto = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await galleryApi.deleteGalleryImage(id)

        dispatch({ type: DELETE_PHOTO, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getVideos = (page) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await galleryApi.getGalleryVideos(page)

        dispatch({ type: GET_VIDEOS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const addVideo = (videoId) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await galleryApi.createGalleryVideo(videoId)

        dispatch({ type: ADD_VIDEO, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const deleteVideo = (videoId) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await galleryApi.deleteGalleryVideo(videoId)

        dispatch({ type: DELETE_VIDEO, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}