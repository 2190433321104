import React from 'react';
import moment from "moment";
import {CalendarIconSmall} from "../../assets/img/icons";

const BlogItem = ({image, title, content, createdAt, _id, deleteBlog, index}) => {
    const handleDeleteBlog = () => {
        deleteBlog(index, _id)
    }

    return (
        <div className={'relative group grid md:grid-cols-[320px,1fr] grid-cols-1 transition hover:shadow-md shadow-[#F1BA5150] hover:border-[#F1BA51] border border-opacity-50 rounded-xl overflow-hidden'}>
            <button onClick={handleDeleteBlog} className={'absolute group-hover:block hidden top-4 right-4 bg-red-500 text-white py-1 px-2 rounded-md'}>Delete</button>
            <div className={'relative overflow-hidden lg:rounded-l-[15px]'}>
                <div className={'absolute top-0 left-0 bg-[#44002C] bg-opacity-25'}></div>
                <img src={image} className={'w-full h-full object-cover'} alt="blog-image"/>
            </div>
            <div className={'lg:py-9 py-4 lg:px-8 px-4'}>
                <h2 className={'text-xl bold line-clamp-2'}>{title}</h2>
                <p className={'flex items-center gap-6 mt-5'}>
                    <CalendarIconSmall type={'purple'} />
                    <span>{moment(createdAt).format('DD.MM.YYYY')}</span>
                </p>
                <p className={'mt-5 md:line-clamp-[12] line-clamp-4'}>{content}</p>
            </div>
        </div>
    );
};

export default BlogItem;