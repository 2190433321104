import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux"
import {CloseIcon, UploadIcon} from "../../assets/img/icons"
import {addVideo} from "../../actions/gallery"
import {TextField} from "@mui/material";

const CreateVideoModal = ({close}) => {
    const [link, setLink] = useState('')
    const [validLink, setValidLink] = useState()
    const dispatch = useDispatch()

    const handleChange = (e) => {
        setLink(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const parsedLink = link.split('v=')[1].split('&')[0]

        dispatch(addVideo(parsedLink))

        close()
        setLink('')
    }

    const checkLinkValidity = () => {
        if(link.trim() !== '') {
            const parsedLink = link.split('v=')[1]?.split('&')[0]

            if(parsedLink && parsedLink.length === 11) {
                setValidLink(true)
            } else {
                setValidLink(false)
            }
        }
    }

    useEffect(() => {
        checkLinkValidity()
    }, [link]);

    return (
        <div className={'fixed w-full h-full top-0 left-0 z-50'}>
            <div onClick={close} className={'absolute w-full h-full top-0 left-0 bg-[#C5B4BF] bg-opacity-50'}></div>
            <div
                className={'absolute border bg-white rounded-md shadow-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-3xl'}>
                <div className={'p-4 border-b flex items-center justify-between'}>
                    <h2 className={'font-medium text-2xl'}>Add new Video</h2>
                    <button onClick={close} className={'p-1 rounded-md transition hover:bg-gray-200'}>
                        <CloseIcon />
                    </button>
                </div>
                <div className={'p-4'}>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <TextField id="outlined-basic" name={'link'} value={link} onChange={handleChange} label="Video Link" variant="outlined" />
                    </div>
                </div>
                <div className='flex justify-end items-center gap-2 p-4 border-t'>
                    <button onClick={close} className='px-2 py-1 transition rounded-md border shadow-md hover:bg-gray-200'>Cancel</button>
                    <button onClick={handleSubmit} disabled={!validLink} className={`px-2 py-1 transition rounded-md border shadow-md ${validLink && 'bg-blue-600 text-white'}`}>OK</button>
                </div>
            </div>
        </div>
    );
};

export default CreateVideoModal;