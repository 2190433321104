import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCurrentCalendar} from "../actions/calendar";
import {CalendarItem} from "../components";

const Calendar = () => {
    const {months} = useSelector(state => state.calendar)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCurrentCalendar())
    }, []);

    return (
        <div>
            <h2 className={'font-medium text-2xl'}>Calendar</h2>

            <div>
                {months?.map(month => <CalendarItem key={month._id} {...month} /> )}
            </div>
        </div>
    );
};

export default Calendar;