import {
    CREATE_UPCOMING_PERFORMANCE,
    DELETE_UPCOMING_PERFORMANCE,
    GET_UPCOMING_PERFORMANCES,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    upcomingPerformances: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_UPCOMING_PERFORMANCE:
            return { ...state, upcomingPerformances: [action.payload.upcomingPerformance, ...state.upcomingPerformances] }
        case GET_UPCOMING_PERFORMANCES:
            return { ...state, upcomingPerformances: action.payload.upcomingPerformances }
        case DELETE_UPCOMING_PERFORMANCE:
            return { ...state, upcomingPerformances: state.upcomingPerformances.filter(upcomingPerformance => upcomingPerformance._id !== action.payload.id) }
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
}