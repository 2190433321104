import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {login, register} from "../actions/auth";

const Login = () => {
    const [formData, setFormData] = useState({ username: '', password: '' })
    const [loginMode, setLoginMode] = useState(true)
    const dispatch = useDispatch()

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleToggleMode = (e) => {
        setLoginMode(!loginMode)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (formData.username && formData.password) {
            loginMode ? dispatch(login(formData)) : dispatch(register(formData))
        } else {
            console.log('fill the fields')
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
                <h2 className="text-2xl font-semibold mb-4">{loginMode ? 'Login' : 'Register'}</h2>
                <div className="mb-4">
                    <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
                        Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        className="w-full p-2 border border-gray-300 rounded outline-none"
                        placeholder="Enter your username"
                        value={formData.username}
                        onChange={handleFormChange}
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                        Password
                    </label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        className="w-full p-2 border border-gray-300 rounded outline-none"
                        placeholder="Enter your password"
                        value={formData.password}
                        onChange={handleFormChange}
                    />
                </div>
                <p onClick={handleToggleMode} className={'font-medium mb-4 cursor-pointer transition hover:text-blue-500'}>{loginMode ? 'Register' : 'Login'}</p>
                <button
                    className="bg-blue-500 text-white p-2 rounded w-full"
                    onClick={handleSubmit}
                >
                    {loginMode ? 'Login' : 'Register'}
                </button>
            </div>
        </div>
    );
};

export default Login;