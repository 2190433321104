import React from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {LogoutIcon} from "../assets/img/icons";
import {useDispatch} from "react-redux";
import {logout} from "../actions/auth";

const Sidebar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogout = () => {
        dispatch(logout())
        navigate('/')
    }

    return (
        <div className={'border-b shadow-md mb-5'}>
            <div className={'max-w-7xl mx-auto my-0 flex justify-between items-center py-4 pr-4'}>
                <ul className={'text-xl flex items-center'}>
                    <li>
                        <NavLink className={({isActive}) => `py-2 px-4 hover:rounded-md hover:bg-gray-200 border-b-2 transition ${isActive ? 'font-medium hover:rounded-b-none hover:rounded-t-md border-b-blue-500' : 'border-b-white'} transition block w-full`}
                                 to={'/'}>Blog</NavLink>
                    </li>
                    <li>
                        <NavLink className={({isActive}) => `py-2 px-4 hover:rounded-md hover:bg-gray-200 border-b-2 transition ${isActive ? 'font-medium hover:rounded-b-none hover:rounded-t-md border-b-blue-500' : 'border-b-white'} transition block w-full`}
                                 to={'/members'}>Members</NavLink>
                    </li>
                    <li>
                        <NavLink className={({isActive}) => `py-2 px-4 hover:rounded-md hover:bg-gray-200 border-b-2 transition ${isActive ? 'font-medium hover:rounded-b-none hover:rounded-t-md border-b-blue-500' : 'border-b-white'} transition block w-full`}
                                 to={'/calendar'}>Calendar</NavLink>
                    </li>
                    <li>
                        <NavLink className={({isActive}) => `py-2 px-4 hover:rounded-md hover:bg-gray-200 border-b-2 transition ${isActive ? 'font-medium hover:rounded-b-none hover:rounded-t-md border-b-blue-500' : 'border-b-white'} transition block w-full`}
                                 to={'/gallery-photos'}>Gallery / Photos</NavLink>
                    </li>
                    <li>
                        <NavLink className={({isActive}) => `py-2 px-4 hover:rounded-md hover:bg-gray-200 border-b-2 transition ${isActive ? 'font-medium hover:rounded-b-none hover:rounded-t-md border-b-blue-500' : 'border-b-white'} transition block w-full`}
                                 to={'/gallery-videos'}>Gallery / Videos</NavLink>
                    </li>
                    <li>
                        <NavLink className={({isActive}) => `py-2 px-4 hover:rounded-md hover:bg-gray-200 border-b-2 transition ${isActive ? 'font-medium hover:rounded-b-none hover:rounded-t-md border-b-blue-500' : 'border-b-white'} transition block w-full`}
                                 to={'/premieres'}>Premieres</NavLink>
                    </li>
                    <li>
                        <NavLink className={({isActive}) => `py-2 px-4 hover:rounded-md hover:bg-gray-200 border-b-2 transition ${isActive ? 'font-medium hover:rounded-b-none hover:rounded-t-md border-b-blue-500' : 'border-b-white'} transition block w-full`}
                                 to={'/legends'}>Legends</NavLink>
                    </li>
                    <li>
                        <NavLink className={({isActive}) => `py-2 px-4 hover:rounded-md hover:bg-gray-200 border-b-2 transition ${isActive ? 'font-medium hover:rounded-b-none hover:rounded-t-md border-b-blue-500' : 'border-b-white'} transition block w-full`}
                                 to={'/hero-slides'}>Hero Slides</NavLink>
                    </li>
                    <li>
                        <NavLink className={({isActive}) => `py-2 px-4 hover:rounded-md hover:bg-gray-200 border-b-2 transition ${isActive ? 'font-medium hover:rounded-b-none hover:rounded-t-md border-b-blue-500' : 'border-b-white'} transition block w-full`}
                                 to={'/upcoming'}>Upcoming</NavLink>
                    </li>
                </ul>
                <button onClick={handleLogout}
                        className={'w-fit py-2 px-4 flex gap-2 items-center rounded-md bg-[#3498db] text-white hover:bg-[#2980b9] transition'}>
                    <LogoutIcon/>
                    <span>Logout</span>
                </button>
            </div>
        </div>
    );
};

export default Sidebar;