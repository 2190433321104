import {CREATE_MEMBER, DELETE_MEMBER, GET_MEMBERS, SELECT_TEAM, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    members: null,
    selectedTeam: 'management',
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MEMBERS:
            return { ...state, members: action.payload.members }
        case CREATE_MEMBER:
            if(action.payload.member.team === state.selectedTeam) {
                return { ...state, members: [action.payload.member, ...state.members]}
            }

            return state
        case DELETE_MEMBER:
            return { ...state, members: state.members.filter(member => member._id !== action.payload.id) }
        case SELECT_TEAM:
            return { ...state, selectedTeam: action.payload.team }
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
}