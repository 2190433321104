export const positions = [
    {
        key: 'director',
        name: "Տնօրեն"
    },
    {
        key: 'deputyDirector',
        name: "Փոխտնօրեն"
    },
    {
        key: 'chiefAccountant',
        name: "Գլխավոր հաշվապահ"
    },
    {
        key: 'accountant',
        name: "Հաշվապահ"
    },
    {
        key: 'headOfHR',
        name: "ՄՌԿ ղեկավար"
    },
    {
        key: 'headOfTheLiteraryDepartment',
        name: "Գրական մասի վարիչ"
    },
    {
        key: 'chiefDirector',
        name: "Գլխավոր ռեժիսոր"
    },
    {
        key: 'responsibleForTheTheatricalPart',
        name: "Բեմադրական մասի պատասխանատու"
    },
    {
        key: 'headOfTheTroupe',
        name: "Թատերախմբի վարիչ"
    },
    {
        key: 'peoplesArtist',
        name: "ՀՀ ժող. արտիստ"
    },
    {
        key: 'honoredArtist',
        name: "ՀՀ վաստակավոր արտիստ"
    },
    {
        key: 'honoredFigureOfArt',
        name: "ՀՀ արվեստի վաստակավոր գործիչ"
    },
    {
        key: 'actress',
        name: "Դերասանուհի"
    },
    {
        key: 'actor',
        name: "Դերասան"
    },
    {
        key: 'assistantDirector',
        name: "Ռեժիսորի օգնական"
    },
    {
        key: 'generalAdministrator',
        name: "Գլխավոր ադմինիստրատոր"
    },
    {
        key: 'prManager',
        name: "PR մենեջեր"
    },
    {
        key: 'archivist',
        name: "Արխիվավար"
    },
    {
        key: 'headOfSoundEquipmentService',
        name: "Ձայնային սարքավորումների ծառ. պետ"
    },
    {
        key: 'graphicDesigner',
        name: "Գրաֆիկ դիզայներ"
    },
    {
        key: 'headOfLightingEquipmentService',
        name: "Լուսային սարքավորումների ծառ. պետ"
    },
    {
        key: 'seniorLightingEquipmentOperator',
        name: "Լուսային սարքավորումների ավագ օպերատոր"
    },
    {
        key: 'lightOperator',
        name: "Լուսային օպերատոր"
    },
    {
        key: 'seniorSoundOperator',
        name: "Հնչունային ավագ օպերատոր"
    },
    {
        key: 'phoneticOperator',
        name: "Հնչունային օպերատոր"
    },
    {
        key: 'dressingRoomManager',
        name: "Հանդերձարանի վարիչ"
    },
    {
        key: 'dresser',
        name: "Հանդերձարար"
    },
    {
        key: 'workshopManager',
        name: "Արհեստանոցի վարիչ"
    },
    {
        key: 'prop',
        name: "Ռեկվիզիտոր"
    },
    {
        key: 'impostor',
        name: "Բուտաֆորիստ"
    },
    {
        key: 'makeupArtistHairdresser',
        name: "Դիմահարդար- վարսահարդար"
    },
    {
        key: 'reminder',
        name: "Հուշարար"
    },
    {
        key: 'seniorStageDriver',
        name: "Բեմի ավագ մեքենավար"
    },
    {
        key: 'firemanWatchman',
        name: "Հրշեջ - պահակ"
    },
    {
        key: 'controller',
        name: "Հսկիչ"
    },
    {
        key: 'stageFitter',
        name: "Բեմի մոնտաժող"
    },
    {
        key: 'storekeeper',
        name: "Պահեստապետ"
    },
    {
        key: 'carpenter',
        name: "Հյուսն"
    },
    {
        key: 'onGuard',
        name: "Պարետ"
    },
    {
        key: 'tailor',
        name: "Դերձակ"
    },
    {
        key: 'responsibleForVisuals',
        name: "Վիզուալների պատասխանատու"
    },
    {
        key: 'washer',
        name: "Լվացարար"
    },
    {
        key: 'cleaner',
        name: "Մաքրուհի"
    },
]