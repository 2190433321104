import {LOGIN, LOGOUT, REGISTER, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    authData: JSON.parse(localStorage.getItem('auth')) || null,
    loading: false
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            localStorage.setItem('auth', JSON.stringify({ ...action?.payload }))

            return { ...state, authData: action.payload }
        case REGISTER:
            localStorage.setItem('auth', JSON.stringify({ ...action?.payload }));

            return { ...state, authData: action.payload }
        case LOGOUT:
            localStorage.clear();

            return { ...state, authData: null }
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
};

export default authReducer;