import {CREATE_NEW_PERFORMANCE, GET_MONTHS, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    months: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MONTHS:
            return {...state, months: action.payload.months}
        case CREATE_NEW_PERFORMANCE:
            return {
                ...state,
                months: state.months.map(month => {
                    if(month._id === action.payload.monthId) {
                        return {
                            ...month,
                            calendar: {
                                ...month.calendar,
                                [action.payload.weekday]: month.calendar[action.payload.weekday].map(dateObj => {
                                    if(dateObj.day === action.payload.date) {
                                        return {
                                            ...dateObj,
                                            performance: action.payload.performance
                                        }
                                    }

                                    return dateObj
                                })
                            }
                        }
                    }

                    return month
                })
            }
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}