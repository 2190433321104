import {
    CREATE_UPCOMING_PERFORMANCE, DELETE_UPCOMING_PERFORMANCE, GET_UPCOMING_PERFORMANCES,
    SET_LOADING
} from '../constants/actionTypes'
import * as upcomingPerformancesApi from '../api/upcoming'

export const createUpcomingPerformance = (formData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await upcomingPerformancesApi.createUpcomingPerformance(formData)

        dispatch({ type: CREATE_UPCOMING_PERFORMANCE, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getUpcomingPerformances = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await upcomingPerformancesApi.getUpcomingPerformances()

        dispatch({ type: GET_UPCOMING_PERFORMANCES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}
export const deleteUpcomingPerformance = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await upcomingPerformancesApi.deleteUpcomingPerformance(id)

        dispatch({ type: DELETE_UPCOMING_PERFORMANCE, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}