import React, {useEffect, useState} from 'react';
import {CreateMemberModal, MemberCard} from "../components";
import {useDispatch, useSelector} from "react-redux";
import {getMembers, selectTeam} from "../actions/members";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const Members = () => {
    const [createMember, setCreateMember] = useState(false)
    const {members, selectedTeam} = useSelector(state => state.members)
    const dispatch = useDispatch()

    const teams = ['management', 'creative', 'service', 'cast']

    const toggleCreate = () => {
        setCreateMember(!createMember)
    }

    const handleSelectTeam = (e) => {
        dispatch(selectTeam(e.target.value))
    }

    useEffect(() => {
        dispatch(getMembers(selectedTeam))
    }, [selectedTeam])

    return (
        <div>
            <div className={'flex items-center justify-between border-b pb-4'}>
                <h2 className={'font-medium text-2xl'}>Members</h2>
                <button onClick={toggleCreate} className={'border rounded-md text-xl py-2 px-4'}>Create</button>
            </div>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Team</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className={'capitalize'}
                    value={selectedTeam}
                    label="Team"
                    name={'team'}
                    onChange={handleSelectTeam}
                >
                    {teams?.map(team => <MenuItem key={team} value={team} className={'capitalize'}>{team}</MenuItem>)}
                </Select>
            </FormControl>
            <div>
                {createMember && <CreateMemberModal close={toggleCreate} />}

                {members?.length > 0
                    ?
                    <div className={'my-10 grid gap-x-6 gap-y-[70px] place-items-center xl:grid-cols-3 md:grid-cols-2 grid-cols-1'}>
                        {members?.map(member => <MemberCard key={member._id} {...member} />)}
                    </div>
                    :
                    <h2 className={'text-center my-10 text-2xl'}>No members</h2>
                }
            </div>
        </div>
    );
};

export default Members;