import React, {useEffect, useState} from 'react';
import {BlogItem, CreateBlogModal, Pagination} from "../components";
import {useDispatch, useSelector} from "react-redux";
import {deleteBlog, getBlogs} from "../actions/blog";

const Blog = () => {
    const [create, setCreate] = useState(false)
    const [page, setPage] = useState(1)
    const {blogs} = useSelector(state => state.blog)
    const dispatch = useDispatch()

    const toggleCreate = () => {
        setCreate(prev => !prev)
    }

    const handleChangePage = (e) => {
        const page = Number(e.target.textContent)
        setPage(page);
    }

    const handleDeleteBlog = (index, id) => {
        if(index !== 'last') {
            dispatch(deleteBlog(id))
        } else {
            if(page > 1) {
                setPage(prev => prev - 1)
                dispatch(getBlogs(page - 1))
            }
            dispatch(deleteBlog(id))
        }
    }

    useEffect(() => {
        dispatch(getBlogs(page))
    }, [page])

    return (
        <div>
            <div className={'flex items-center justify-between'}>
                <h2 className={'font-medium text-2xl'}>Blog</h2>
                <button onClick={toggleCreate} className={'border rounded-md text-xl py-2 px-4'}>Create</button>
            </div>
            <div>
                {create && <CreateBlogModal close={toggleCreate} />}

                <div className={'grid grid-cols-1 gap-4 my-10'}>
                    {blogs?.data?.length > 0
                        ?
                        blogs?.data.map(blog => <BlogItem key={blog._id} index={blogs?.data?.length === 1 ? 'last' : null} deleteBlog={handleDeleteBlog} {...blog} />)
                        :
                        <h2 className={'text-center my-10 text-2xl'}>No blogs</h2>}
                </div>
                <Pagination count={blogs?.numberOfPages} page={page} handleChange={handleChangePage} />
            </div>
        </div>
    )
}

export default Blog;