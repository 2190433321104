import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {CreateHeroSlideModal, HeroSlideCard} from "../components";
import {deleteHeroSlide, getHeroSlides} from "../actions/heroSlides";

const HeroSlides = () => {
    const [create, setCreate] = useState(false)
    const {heroSlides} = useSelector(state => state.heroSlides)
    const dispatch = useDispatch()

    const toggleCreate = () => {
        setCreate(prev => !prev)
    }

    const handleDelete = (id) => {
        dispatch(deleteHeroSlide(id))
    }

    useEffect(() => {
        dispatch(getHeroSlides())
    }, [])

    return (
        <div>
            <div className={'flex items-center justify-between'}>
                <h2 className={'font-medium text-2xl'}>Hero slides</h2>
                <button onClick={toggleCreate} className={'border rounded-md text-xl py-2 px-4'}>Create</button>
            </div>
            <div className={'my-10 grid gap-x-6 gap-y-[70px] place-items-baseline md:grid-cols-2 grid-cols-1'}>
                {create && <CreateHeroSlideModal close={toggleCreate}/>}

                {heroSlides?.map(slide => <HeroSlideCard key={slide._id} handleDelete={handleDelete} {...slide} />)}
            </div>
        </div>
    )
}

export default HeroSlides;