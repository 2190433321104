import React from 'react';

const UpcomingPerformanceCard = ({image, _id, handleDelete}) => {
    return (
        <div className={'relative group w-fit border rounded-lg overflow-hidden'}>
            <button onClick={() => handleDelete(_id)} className={'absolute group-hover:block hidden top-4 right-4 bg-red-500 text-white py-1 px-2 rounded-md'}>Delete</button>

            <img src={image} alt="legend"/>
        </div>
    );
};

export default UpcomingPerformanceCard;