import {CREATE_LEGEND, DELETE_LEGEND, GET_LEGENDS, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    legends: null,
    loading: false
}

const legendsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_LEGEND:
            return { ...state, legends: [action.payload.legend, ...state.legends] }
        case GET_LEGENDS:
            return { ...state, legends: action.payload.legends }
        case DELETE_LEGEND:
            return { ...state, legends: state.legends.filter(legend => legend._id !== action.payload.id) }
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
};

export default legendsReducer;