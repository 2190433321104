import React from 'react'
import {CalendarIconSmall} from "../../assets/img/icons"
import moment from 'moment'

const PremiereCard = ({image, genre, date, title, _id}) => {
    const modifiedDate = moment(date).format('DD.MM.YYYY')

    return (
        <div className={'relative group md:max-w-[310px] max-w-[345px] w-full rounded-xl overflow-hidden'}>
            <div>
                <img src={image} className={'w-full'} alt="cover-image"/>
            </div>
            <div className={'flex flex-col gap-6 pt-4 pb-8 px-3 bg-[#77345A] text-white'}>
                <h2 className={'bold leading-5 text-xl'}>{title}</h2>
                <p>{genre}</p>
                <p className={'flex items-center gap-1'}>
                    <CalendarIconSmall />
                    <span>{modifiedDate}</span>
                </p>
            </div>
        </div>
    )
}

export default PremiereCard