import {CREATE_BLOG, DELETE_BLOG, GET_BLOGS, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    blogs: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BLOGS:
            return {...state, blogs: action.payload}
        case CREATE_BLOG:
            const lastRemoved = state.blogs.data
            state.blogs.data.length === 5 && lastRemoved.pop()

            return {
                ...state,
                blogs: {
                    data: [action.payload.blog, ...lastRemoved],
                    numberOfPages: action.payload.numberOfPages
                }
            }
        case DELETE_BLOG:
            return {
                ...state,
                blogs: {...state.blogs, data: state.blogs.data.filter(blog => blog._id !== action.payload.id)}
            }
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}