import React, {useEffect, useState} from 'react';
import {CreateVideoModal, Pagination, VideoThumbnailItem} from "../components";
import {deleteVideo, getVideos} from "../actions/gallery";
import {useDispatch, useSelector} from "react-redux";

const GalleryVideos = () => {
    const {videos} = useSelector(state => state.gallery)
    const [page, setPage] = useState(1)
    const [create, setCreate] = useState(false)
    const dispatch = useDispatch()

    const toggleCreate = () => {
        setCreate(prev => !prev)
    }

    const handleChangePage = (e) => {
        const page = Number(e.target.textContent)
        setPage(page);
    }

    const handleDelete = async (id) => {
        dispatch(deleteVideo(id))
    }

    useEffect(() => {
        dispatch(getVideos(page))
        window.scrollTo({top: 0, left: 0})
    }, [page])

    return (
        <div>
            <div className={'flex items-center justify-between'}>
                <h2 className={'font-medium text-2xl'}>Gallery Videos</h2>
                <button onClick={toggleCreate} className={'border rounded-md text-xl py-2 px-4'}>Create</button>
            </div>
            <div>
                {create && <CreateVideoModal close={toggleCreate} />}

                <div
                    className={'grid place-items-baseline lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-[6%] gap-y-[100px] mx-auto my-0 mt-12'}>
                    {videos?.data.map(video => <VideoThumbnailItem key={video._id} handleDelete={handleDelete} {...video} />)}
                </div>
                <div className={'flex items-center justify-center mt-[120px] mb-[80px] gap-5'}>
                    <Pagination count={videos?.numberOfPages} page={page} handleChange={handleChangePage} />
                </div>
            </div>
        </div>
    );
};

export default GalleryVideos;