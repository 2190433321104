import React, {useState} from 'react';
import {PlusIcon, TimeIcon} from "../../assets/img/icons";
import CreateNewPerformance from "./CreateNewPerformance";

const PerformanceCard = ({image, title, time}) => (
    <div className={'flex flex-col'}>
        <div className={'w-[100px] aspect-[1/1.6]'}>
            <img src={image} className={'w-full h-full object-cover rounded-md'} alt="premiere-image"/>
        </div>
        <div className={'mt-4'}>
            <p className={'text-sm'}>{title}</p>
            <p className={'flex items-center gap-2 text-sm text-[#393E46]'}>
                <TimeIcon/>
                <span>{time}</span>
            </p>
        </div>
    </div>
)

const Calendar = ({calendar, name, year, code, index, _id}) => {
    const [newPerformance, setNewPerformance] = useState(null)
    const weekdaysArray = calendar && Object.entries(calendar)

    const handleCloseNewPerformanceModal = () => {
        setNewPerformance(null)
    }

    return (
        <div className='py-4 relative w-full' key={_id}>
            {newPerformance && <CreateNewPerformance close={handleCloseNewPerformanceModal} {...newPerformance} />}

            <h2 className={`capitalize font-medium text-5xl text-[#44002C] mb-20 mt-10`}>{name} {year}</h2>

            <div className={`w-full flex justify-between`}>
                {weekdaysArray?.map((weekDayObj => <div key={weekDayObj[0]}
                                                        className='text-[20px] min-w-[100px] bg-white mb-5 text-[#393E46] text-center capitalize'>{weekDayObj[0]}</div>))}
            </div>
            <div className='relative grid grid-cols-7 mt-8 pb-7'>
                {weekdaysArray?.map(weekDayObj => (
                    <div key={weekDayObj[0]}>
                        <div>
                            {weekDayObj[1].map(day => {
                                const handleCreateNewPerformance = () => {
                                    setNewPerformance({
                                        monthId: _id,
                                        weekday: weekDayObj[0],
                                        date: day.day,
                                        monthName: name,
                                        monthIndex: index + 1,
                                        year
                                    })
                                }
                                return (
                                    day
                                        ?
                                        <div
                                            key={day.day}
                                            className='relative group w-full aspect-[1/1.76] border border-[#44002C] border-opacity-20 lg:text-[20px] text-sm leading-[46px] pt-5 pl-4'
                                        >
                                            <span className={'absolute z-10 top-0 right-0 text-2xl'}>{day.day}</span>
                                            <div
                                                className={'absolute group-hover:opacity-100 opacity-0 group-hover:visible invisible transition w-full h-full top-0 left-0 z-1 bg-[#C5B4BF] bg-opacity-50 flex items-center justify-center'}>
                                                <button onClick={handleCreateNewPerformance}>
                                                    <PlusIcon/>
                                                </button>
                                            </div>
                                            {day.performance && <PerformanceCard {...day.performance} />}
                                        </div>
                                        :
                                        <div key={`${Math.random() * Math.random()}`}
                                             className='w-full aspect-[1/1.76]'></div>
                                )
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default Calendar;