import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {CreateUpcomingPerformanceModal, UpcomingPerformanceCard} from "../components"
import {deleteUpcomingPerformance, getUpcomingPerformances} from "../actions/upcoming"

const UpcomingPerformances = () => {
    const [create, setCreate] = useState(false)
    const {upcomingPerformances} = useSelector(state => state.upcoming)
    const dispatch = useDispatch()

    const toggleCreate = () => {
        setCreate(prev => !prev)
    }

    const handleDelete = (id) => {
        dispatch(deleteUpcomingPerformance(id))
    }

    useEffect(() => {
        dispatch(getUpcomingPerformances())
    }, [])

    return (
        <div>
            <div className={'flex items-center justify-between'}>
                <h2 className={'font-medium text-2xl'}>Upcoming performances</h2>
                <button onClick={toggleCreate} className={'border rounded-md text-xl py-2 px-4'}>Create</button>
            </div>
            <div>
                {create && <CreateUpcomingPerformanceModal close={toggleCreate}/>}

                {upcomingPerformances?.length > 0
                    ?
                    <div
                        className={'my-10 grid gap-x-6 gap-y-[70px] place-items-center xl:grid-cols-3 md:grid-cols-2 grid-cols-1'}
                    >
                        {upcomingPerformances?.map(upcomingPerformance => <UpcomingPerformanceCard key={upcomingPerformance._id} handleDelete={handleDelete} {...upcomingPerformance} />)}
                    </div>
                    :
                    <p className={'text-center'}>No upcoming performances</p>
                }
            </div>
        </div>
    )
}

export default UpcomingPerformances;