export const UploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15" />
    </svg>
)

export const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
)
export const CalendarIconSmall = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.4583 2.83337H3.54167C2.75926 2.83337 2.125 3.46764 2.125 4.25004V14.1667C2.125 14.9491 2.75926 15.5834 3.54167 15.5834H13.4583C14.2407 15.5834 14.875 14.9491 14.875 14.1667V4.25004C14.875 3.46764 14.2407 2.83337 13.4583 2.83337Z"
            stroke="#44002C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.3333 1.41663V4.24996" stroke="#44002C" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M5.66666 1.41663V4.24996" stroke="#44002C" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M2.125 7.08337H14.875" stroke="#44002C" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>
)

export const PlusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
)
export const LogoutIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
    </svg>
)

export const TimeIcon = () => (
    <svg width="14" height="14" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_113_779)">
            <path d="M5.5 10.3125C4.54818 10.3125 3.61773 10.0303 2.82632 9.50145C2.03491 8.97265 1.41808 8.22104 1.05383 7.34167C0.689585 6.4623 0.594282 5.49466 0.779973 4.56113C0.965664 3.6276 1.42401 2.77009 2.09705 2.09705C2.77009 1.42401 3.6276 0.965664 4.56113 0.779973C5.49466 0.594282 6.4623 0.689585 7.34167 1.05383C8.22104 1.41808 8.97265 2.03491 9.50145 2.82632C10.0303 3.61773 10.3125 4.54818 10.3125 5.5C10.3125 6.77636 9.80547 8.00043 8.90295 8.90295C8.00043 9.80547 6.77636 10.3125 5.5 10.3125ZM5.5 1.375C4.68415 1.375 3.88663 1.61693 3.20828 2.07019C2.52992 2.52345 2.00121 3.16769 1.689 3.92143C1.37679 4.67518 1.2951 5.50458 1.45426 6.30475C1.61343 7.10492 2.00629 7.83993 2.58319 8.41682C3.16008 8.99371 3.89508 9.38658 4.69525 9.54574C5.49543 9.7049 6.32483 9.62322 7.07857 9.311C7.83232 8.99879 8.47655 8.47008 8.92981 7.79173C9.38307 7.11338 9.625 6.31585 9.625 5.5C9.625 4.40598 9.1904 3.35677 8.41682 2.58319C7.64323 1.8096 6.59402 1.375 5.5 1.375Z" fill="#44002C"/>
            <path d="M7.07781 7.5625L5.15625 5.64094V2.40625H5.84375V5.35562L7.5625 7.07781L7.07781 7.5625Z" fill="#44002C"/>
        </g>
        <defs>
            <clipPath id="clip0_113_779">
                <rect width="11" height="11" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)