import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {createBlog} from "../../actions/blog";
import {convertImageFileToBase64} from "../../utils/convertImageFileToBase64";
import {CloseIcon, UploadIcon} from "../../assets/img/icons";
import {TextareaAutosize, TextField} from "@mui/material";

const initialFormData = {
    title: '',
    link: '',
    image: null,
    content: ''
}

const CreateBlogModal = ({close}) => {
    const [data, setData] = useState(initialFormData)
    const [validForm, setValidForm] = useState(false)
    const [imagePreview, setImagePreview] = useState('')
    const dispatch = useDispatch()

    const handleChange = (e) => {
        if (!e.target.files) {
            setData({...data, [e.target.name]: e.target.value})
        } else {
            setData({...data, image: e.target.files[0]})
        }
    }

    const handleSubmit = async (e) => {
        const formData = new FormData()

        for(const key in data) {
            formData.append(key, data[key])
        }

        dispatch(createBlog(formData))

        close()
        setData(initialFormData)
    }

    const checkFormValidity = () => {
        const isValid = data.image && data.title.trim() !== '' && data.content.trim() !== ''
        setValidForm(isValid)
    }

    useEffect(() => {
        data.image && convertImageFileToBase64(data.image, setImagePreview)
    }, [data.image])

    useEffect(() => {
        checkFormValidity()
    }, [data.title, data.image, data.content])

    return (
        <div className={'fixed w-full h-full top-0 left-0 z-50'}>
            <div onClick={close} className={'absolute w-full h-full top-0 left-0 bg-[#C5B4BF] bg-opacity-50'}></div>
            <div
                className={'absolute border bg-white rounded-md shadow-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-3xl'}>
                <div className={'p-4 border-b flex items-center justify-between'}>
                    <h2 className={'font-medium text-2xl'}>Create new Blog</h2>
                    <button onClick={close} className={'p-1 rounded-md transition hover:bg-gray-200'}>
                        <CloseIcon />
                    </button>
                </div>
                <div className={'p-4'}>
                    <div className={'flex flex-col gap-1'}>
                        <TextField id="outlined-basic" name={'title'} value={data.title} onChange={handleChange} label="Title" variant="outlined" />
                    </div>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <textarea id="outlined-basic" className={'border border-gray-400 rounded-md h-36 outline-none p-2'} name={'content'} value={data.content} onChange={handleChange} placeholder={'Description'} />
                    </div>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <TextField id="outlined-basic" name={'link'} value={data.link} onChange={handleChange} label="Link" variant="outlined" />
                    </div>
                    <div className={'flex flex-col gap-1 mt-5'}>
                        <label className={'flex items-center justify-between'}>
                            <div className={'flex flex-col items-start'}>
                                <span>Image</span>
                                <input type="file" onChange={handleChange} name={'image'} accept="image/jpeg, image/png"
                                       className={'hidden'} id={'image'}/>
                                <label
                                    className={'flex items-center gap-1 cursor-pointer shadow-md border rounded-md py-1 px-2 mt-1'}
                                    htmlFor="image">
                                    <UploadIcon/>
                                    <span>Upload</span>
                                </label>
                            </div>
                            <div>
                                {imagePreview && <img className={'max-w-[150px] shadow-md rounded-md select-none pointer-events-none'} src={imagePreview} alt="image preview" />}
                            </div>
                        </label>
                    </div>
                </div>
                <div className='flex justify-end items-center gap-2 p-4 border-t'>
                    <button onClick={close} className='px-2 py-1 transition rounded-md border shadow-md hover:bg-gray-200'>Cancel</button>
                    <button onClick={handleSubmit} disabled={!validForm} className={`px-2 py-1 transition rounded-md border shadow-md ${validForm && 'bg-blue-600 text-white'}`}>OK</button>
                </div>
            </div>
        </div>
    );
};

export default CreateBlogModal;