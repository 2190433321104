import React from 'react';

const VideoThumbnailItem = ({thumbnail, title, handleDelete, _id}) => {
    return (
        <div className={'relative group w-full'}>
            <button onClick={() => handleDelete(_id)} className={'absolute top-4 right-4 group-hover:block hidden rounded-md bg-red-500 py-1 px-2 text-white'}>Delete</button>
            <img src={thumbnail} className="w-full h-full object-cover" alt="thumbnail"/>
            <p className={'line-clamp-2 text-xl mt-2'}>{title}</p>
        </div>
    )
};

export default VideoThumbnailItem;