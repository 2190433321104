import {
    ADD_PHOTO,
    ADD_VIDEO,
    DELETE_PHOTO,
    DELETE_VIDEO,
    GET_PHOTOS,
    GET_VIDEOS,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    photos: null,
    videos: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PHOTOS:
            return { ...state, photos: action.payload }
        case ADD_PHOTO:
            return {
                ...state,
                photos: {
                    ...state.photos,
                    data: [...action.payload.photos, ...state.photos.data]
                }
            }
        case DELETE_PHOTO:
            return {
                ...state,
                photos: {...state.photos, data: state.photos.data.filter(photo => photo._id !== action.payload.id)}
            }
        case GET_VIDEOS:
            return { ...state, videos: action.payload }
        case ADD_VIDEO:
            const lastRemoved = state.videos.data
            state.videos.data.length === 10 && lastRemoved.pop()

            return {
                ...state,
                videos: {
                    ...state.videos,
                    data: [action.payload.video, ...lastRemoved]
                }
            }
        case DELETE_VIDEO:
            return {
                ...state,
                videos: {...state.videos, data: state.videos.data.filter(video => video._id !== action.payload.id)}
            }
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
}