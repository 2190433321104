import {CREATE_PREMIERE, DELETE_PREMIERE, GET_PREMIERES, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    premieres: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PREMIERES:
            return {...state, premieres: action.payload.premieres}
        case CREATE_PREMIERE:
            return {...state, premieres: [action.payload.premiere, ...state.premieres]}
        case DELETE_PREMIERE:
            return {...state, premieres: state.premieres.filter(premiere => premiere._id !== action.payload.id)}
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}