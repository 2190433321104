import React from 'react';
import {useDispatch} from "react-redux";
import {deleteMember} from "../../actions/members";

const MemberCard = ({name, avatar, _id}) => {
    const dispatch = useDispatch()

    const handleDeleteMember = () => {
        dispatch(deleteMember(_id))
    }

    return (
        <div className={'relative group max-w-[410px] z-0 rounded-md overflow-hidden transition hover:bg-gray-100'}>
            <button onClick={handleDeleteMember} className={'absolute z-50 group-hover:block hidden top-4 right-4 bg-red-500 text-white py-1 px-2 rounded-md'}>Delete</button>

            <div className={'relative w-[296px] h-[296px] rounded-md overflow-hidden'}>
                <img src={avatar} className={'w-full absolute top-0'} alt="member-image"/>
            </div>
            <div className={'mt-5 text-center'}>
                <h2 className={'bold mb-5 text-2xl'}>{name.am}</h2>
            </div>
        </div>
    );
};

export default MemberCard;