import React, {useEffect, useState} from 'react';
import {CreatePhotoModal, ImageItem, Pagination} from "../components";
import {useDispatch, useSelector} from "react-redux";
import {deletePhoto, getPhotos} from "../actions/gallery";

const GalleryPhotos = () => {
    const {photos} = useSelector(state => state.gallery)
    const [page, setPage] = useState(1)
    const [create, setCreate] = useState(false)
    const dispatch = useDispatch()

    const toggleCreate = () => {
        setCreate(prev => !prev)
    }

    const handleChangePage = (e) => {
        const page = Number(e.target.textContent)
        setPage(page);
    }

    const handleDelete = (id) => {
        dispatch(deletePhoto(id))
    }

    useEffect(() => {
        dispatch(getPhotos(page))
    }, [page])

    return (
        <div>
            <div className={'flex items-center justify-between'}>
                <h2 className={'font-medium text-2xl'}>Gallery Photos</h2>
                <button onClick={toggleCreate} className={'border rounded-md text-xl py-2 px-4'}>Create</button>
            </div>
            <div>
                {create && <CreatePhotoModal close={toggleCreate} />}

                <div
                    className={'grid place-items-center lg:grid-cols-2 grid-cols-1 gap-x-[6%] gap-y-[100px] mx-auto my-0 mt-12'}>
                    {photos?.data.map(image => <ImageItem key={image._id} {...image} handleDelete={handleDelete} />)}
                </div>
                <div className={'flex items-center justify-center mt-[120px] mb-[80px] gap-5'}>
                    <Pagination count={photos?.numberOfPages} page={page} handleChange={handleChangePage} />
                </div>
            </div>
        </div>
    );
};

export default GalleryPhotos;